import React, { Component } from 'react';
import {
    Alert, Button, Spinner, Table,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageStatus } from 'enums';
import { Show } from "../../Layout";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import moment from "moment/moment";
import { exportToExcel } from "../../Utils/ExportToExcel";
import { SurveysAPI } from "../../API";
import { withRouter } from "react-router";
import { SurveyEmailScheduleForm } from "./SurveyEmailScheduleForm";
import { SurveyEmailScheduleDetails } from "./SurveyEmailScheduleDetails";
import Swal from 'sweetalert2';

const MODAL_TYPES = {
    NONE: 'NONE',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    DETAILS: 'DETAILS',
    BODY: 'BODY'
};

type State = {
    status: PageStatus,
    error: string | null,
    formType: string,
    body: string | null,
    data: any[],
    id?: string | null,
    filteredData: any[],
    filters: {
        name: '',
    },
};


class SurveyEmailSchedule extends Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            status: PageStatus.None,
            error: null,
            formType: MODAL_TYPES.NONE,
            data: [],
            id: null,
            body: null,
            filteredData: [],
            filters: {
                name: '',
            },
        };
        this.fetchList = this.fetchList.bind(this);
    }

    componentDidMount() {
        if (this.props.id) {
            this.fetchList()
        }
    }

    fetchList(): Promise<void> {
        return Promise.resolve()
            .then(() => this.setState({ status: PageStatus.Loading }))
            .then(() => SurveysAPI.getAllEmailSchedule(this.props.id, 10000))
            .then((countries) => {
                this.setState({ data: countries, filteredData: countries, status: PageStatus.Loaded });
            })
            .catch((error) => {
                this.setState({ error: error.message, status: PageStatus.Error });
            });
    }

    isShowDetailModal(): boolean {
        return this.state.formType === MODAL_TYPES.DETAILS
            || this.state.formType === MODAL_TYPES.DELETE;
    }


    handleFilterChange = (e) => {
        const { name, value } = e.target;
        this.setState(
            (prevState) => ({
                filters: {
                    ...prevState.filters,
                    [name]: value,
                },
            }),
        );
    };

    applyFilters = () => {
        const { data } = this.state;
        const { filters } = this.state;
        const filteredData = data.filter((user) => {
            return Object.keys(filters).every((key) => {
                if (filters[key] === '') return true;
                if (key === 'publishDate' || key === 'expiryDate') {
                    return new Date(user[key]).toDateString() === new Date(filters[key]).toDateString();
                }
                return String(user[key]).toLowerCase().includes(String(filters[key]).toLowerCase());
            });
        });
        this.setState({ filteredData });
    }


    clearFilter = () => {
        this.setState({
            filters: {
                name: '',
            },
        })
        this.fetchList()
    }

    handleExport() {
        exportToExcel(this.state.filteredData, 'DashboardTemplates');
    };

    //======= resend Reminder =====
    reminderResend = async (id) => {
        const modals = document.getElementsByClassName('fade modal show');
        Array.from(modals).forEach(modal => {
            modal.style.zIndex = '0';
        });
        const messageHtml = `<div class="row">
                <label class="col-md-3">
                    <input type="checkbox" id="email" checked>&nbsp;&nbsp;&nbsp;&nbsp;Email
                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label class="col-md-3">
                    <input type="checkbox" id="whatsapp">&nbsp;&nbsp;&nbsp;&nbsp;WhatsApp
                </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label class="col-md-3">
                    <input type="checkbox" id="sms">&nbsp;&nbsp;&nbsp;&nbsp;SMS
                </label>
            </div>
            <br>
            <div id="costWarning" style="color: #ff9068; display: none;">
                Warning: WhatsApp and SMS may incur Internal costs.
            </div> `;

        const container = document.createElement('div');
        container.innerHTML = messageHtml;

        const whatsappCheckbox = container.querySelector('#whatsapp');
        const smsCheckbox = container.querySelector('#sms');
        const warningMessage = container.querySelector('#costWarning');

        const updateWarning = () => {
            const whatsappChecked = whatsappCheckbox.checked;
            const smsChecked = smsCheckbox.checked;
            warningMessage.style.display = (whatsappChecked || smsChecked) ? 'block' : 'none';
        };

        whatsappCheckbox.addEventListener('change', updateWarning);
        smsCheckbox.addEventListener('change', updateWarning);

        const { value: selectedOptions } = await Swal.fire({
            title: 'Resend Reminder',
            html: container,
            showCancelButton: true,
            confirmButtonText: 'Send',
            cancelButtonText: 'Cancel',
        });

        if (selectedOptions) {
            const emailChecked = container.querySelector('#email').checked;
            const whatsappChecked = whatsappCheckbox.checked;
            const smsChecked = smsCheckbox.checked;

            if (!emailChecked && !whatsappChecked && !smsChecked) {
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Please select at least one option to send the reminder.',
                });
                Array.from(modals).forEach(modal => {
                    modal.style.zIndex = '';
                });
                return;
            }

            const data = {
                id: id,
                email: emailChecked ? 'Y' : 'N',
                whatsapp: whatsappChecked ? 'Y' : 'N',
                sms: smsChecked ? 'Y' : 'N',
            };
            try {
                const response = await SurveysAPI.ResendReminderApi({ data });
                const resendButton = document.getElementById('resendbutton');
                resendButton.disabled = response.count === 3;
                if (response.status === 1) {
                    Swal.fire({
                        title: "Success!",
                        text: "Reminder has been sent successfully.",
                        icon: "success",
                        button: "OK",
                    });
                    const emailsArray = response.data.emailsArray;
                    emailsArray.forEach((emailContent, index) => {
                        console.log(`Email ${index + 1}:`, emailContent);
                    });

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.message || "Failed to send reminder.",
                        icon: "error",
                        button: "OK",
                    });
                }
            } catch (error) {
                console.error("Error sending reminder:", error);
                Swal.fire({
                    title: "Error!",
                    text: error,
                    icon: "error",
                    button: "OK",
                });
            }

        } else {
            console.log("User cancelled the modal.");
        }

        Array.from(modals).forEach(modal => {
            modal.style.zIndex = '';
        });
    };

    // ==============================================================

    render() {
        const { filteredData, filters } = this.state;
        return (
            <>
                <GridContainer>
                    <Card>
                        <CardHeader>
                            <div className="d-flex align-items-center justify-content-between">
                                <h4>Email Schedule</h4>
                                <div>
                                    <Button
                                        onClick={() => {
                                            return this.setState({
                                                formType: MODAL_TYPES.CREATE,
                                            });
                                        }}
                                        variant="primary"
                                        size="sm"
                                        className="mx-1"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="mr-2" />
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>
                    </Card>
                </GridContainer>

                <div className="jumbotron bg-white p-3 border shadow-sm">
                    <Alert variant="danger" show={this.state.status === PageStatus.Error}>
                        {this.state.error}
                    </Alert>

                    <Show when={this.state.status === PageStatus.Loading}>
                        <div className="d-flex justify-content-center w-100 p-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </Show>

                    <Show when={this.state.status === PageStatus.Loaded}>
                        <Show when={this.state.formType === MODAL_TYPES.CREATE}>
                            <SurveyEmailScheduleForm
                                show={this.state.formType === MODAL_TYPES.CREATE}
                                surveyId={this.props.id}
                                onClose={() => this.setState({
                                    formType: MODAL_TYPES.NONE,
                                })}
                                onSubmit={(id) => {
                                    this.fetchList();
                                    this.setState({
                                        formType: MODAL_TYPES.DETAILS, id: id,
                                    });
                                }}
                            />
                        </Show>

                        <Show when={!this.state.filteredData.length}>
                            <Alert variant="info" show={!this.state.filteredData.length}>
                                At the current moment data is not available, Click button for add.
                            </Alert>
                        </Show>

                        <Show when={!!this.state.filteredData.length}>
                            <Show when={this.isShowDetailModal()}>
                                <SurveyEmailScheduleDetails
                                    id={this.state.id}
                                    isUnique={this.props.isUnique}

                                    onClose={() => this.setState({
                                        formType: MODAL_TYPES.NONE,
                                        id: null,
                                    })}
                                    onUpdate={() => {
                                        this.setState({
                                            formType: MODAL_TYPES.UPDATE,
                                        });
                                    }}
                                    onDelete={(id) => {
                                        this.fetchList();
                                        this.setState({
                                            formType: MODAL_TYPES.NONE,
                                            id: null,
                                        });
                                    }}
                                />
                            </Show>

                            <Show when={this.state.formType === MODAL_TYPES.UPDATE}>
                                <SurveyEmailScheduleForm
                                    show={this.state.formType === MODAL_TYPES.UPDATE}
                                    id={this.state.id}
                                    surveyId={this.props.id}
                                    onClose={() => this.setState({
                                        formType: MODAL_TYPES.NONE,
                                        id: null,
                                    })}
                                    onSubmit={(id) => {
                                        this.fetchList();
                                        this.setState({
                                            formType: MODAL_TYPES.DETAILS, id: id,
                                        });
                                    }}
                                />
                            </Show>

                            <Table responsive size="sm" bordered>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Count</th>
                                        <th>Unique Links Uploaded</th>
                                        <th>Users Count</th>
                                        <th>Sample</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        this.state.filteredData.map((info, index) => (
                                            <tr key={info.id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <span
                                                        aria-label="button"
                                                        role="button"
                                                        tabIndex={0}
                                                        className="text-primary"
                                                        onKeyPress={() => null}
                                                        onClick={() => {
                                                            this.setState({
                                                                formType: MODAL_TYPES.DETAILS,
                                                                id: info.id,
                                                            });
                                                        }}
                                                    >{moment(info.scheduleDate).format('MM/DD/YYYY HH:mm A')}</span>
                                                </td>
                                                <td>{info.scheduleType}</td>
                                                <td>{info.scheduleStatus}</td>
                                                {/* <td>{info.count > 0 ? info.count : 'Send to all'}</td> */}
                                                <td>{info.count}</td>
                                                <td>{info.isuniqueuploaded === true ? "Uploaded" : 'Not Uploaded'}</td>
                                                <td>{info.totalUserCount}</td>
                                                <td>{info.sampleName}</td>
                                                {/* <td><button onClick={() => this.reminderResend(info.id)} className='btn btn-sm btn-info' id='resendbutton'>Resend request</button></td> */}
                                                <td>
                                                        <button 
                                                            onClick={() => this.reminderResend(info.id)} 
                                                            className='btn btn-sm btn-info' 
                                                            id='resendbutton' 
                                                            disabled={info.resendApplicability == 'N'} 
                                                        >
                                                            Resend request
                                                        </button>
                                                    </td>

                                            </tr>
                                        ))
                                    }
                                </tbody>

                            </Table>
                        </Show>
                    </Show>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userId: state.adminUser.adminUser.userId,
    };
}


const SurveyEmailScheduleWithRouter = withRouter(SurveyEmailSchedule);

export { SurveyEmailScheduleWithRouter as SurveyEmailSchedule };
