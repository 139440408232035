import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import {
  Field,
  reduxForm,
} from 'redux-form';
import { Alert, Modal, Spinner, Table, } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Show } from 'Layout';
import { PageStatus } from 'enums';
import { SamplesAPI } from "../../API";
import { ProfileManagementAPI } from "../../API/ProfileManagementAPI";
import Select from 'react-select';
import moment from "moment";
import { CountriesAPI } from "../../API/CountriesAPI";
import axios from 'axios';
import { number } from 'prop-types'; //chng
import {Confirmation} from "../../Shared/Confirmation";

export type FormValue = {
  name: string,
};


type State = {
  status: string,
  error: string | null,
  data: any,
  name: string,
};

function removeDuplicates(arr, property) {
  const uniqueMap = {};
  return arr.filter(obj => {
    if (obj[property]) {
      const value = obj[property];
      if (!uniqueMap[value]) {
        uniqueMap[value] = true;
        return true;
      }
      return false;
    }
  });
}

class Form extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      status: PageStatus.None,
      isLoading: true,
      error: null,
      data: '',
      name: "",
      description: "",
      "gender": '',
      "isActive": true,
      "profileCount": 0,
      "fromAge": null,
      "toAge": null,
      "fromRegistrationDate": null,
      "toRegistrationDate": null,
      "stateIds": [],
      "cityIds": [],
      "tierIds": [],
      segmentsIds: [],
      regionsIds: [],
      states: [],
      cities: [],
      tiers: [],
      segments: [],
      regions: [],
      selectedStatesOption: [],
      selectedCitiesOption: [],
      selectedTiersOption: [],
      selectedSegmentsOption: [],
      selectedRegionsOption: [],
      genderOptions: [{ label: "Male", value: 'Male' }, { label: "Female", value: 'Female' }, { label: "Other", value: 'Other' }],
      dynamicFields: [{
        gender: '',
        fromAge: null,
        toAge: null
      }],
      selectedRegionValues: [],//chng
      selectedTiers: [],//chng
      selectedStates: [],//chng
      selectedSegments: [],//chng
      selectedCities: [],//chng
      selectedGenders: [],//chng
      accordience: [],
      profileAnswers: [],
      selectedProfileId: null,
      selectedQuestionId: null,
      isCheckboxDisabled: false, // State to track if checkboxes should be disabled
      profileQuestion: [],
      profileTarget: [],
      questionTarget: [], // Initialize as an empty array
      answersTarget: [], // Initialize as an empty array
      disabledCheckboxes: {}, // Initialize disabledCheckboxes as an empty object
      updatecheckedProfileTarget: false,
      updatecheckedQuestions: false,
      updatecheckedAnswers: false,
      selectedTableItemsArray: [],
      minToRegistrationDate: "",
      maxFromRegistrationDate: "",
      enableDisableTargetPanelistButton: {"status":false, "text":"Update Targeted Panelists"},
    };
  }

  componentDidMount() {
    if (!!this.props.id) {
      this.fetchDetails();
    } else {
      this.fetchList()
    }
  }

  fetchDetails() {
    Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => {
        if (!this.props.id) {
          return Promise.reject(new Error('Invalid ID'));
        }

        return SamplesAPI.getOne(this.props.id);
      })
      .then((data: any) => {
        this.initializeValues(data.sample);
        this.setState({
          data: data.sample,
          status: PageStatus.Loaded,
        }, () => {
          this.fetchList()
          // if(data.sample.regions.length > 0) {
          //   this.handleRegionChange(data.sample.regions)
          // }
          // if(data.sample.tierIds.length > 0) {
          //   this.handleTierChange(data.sample.tierIds)
          // }
          // if(data.sample.stateIds.length > 0) {
          //   this.handleStateChange(data.sample.stateIds)
          // }
          // if(data.sample.segments.length > 0) {
          //   this.handleSegmentChange(data.sample.segments)
          // }
        });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  fetchList(): Promise<void> {
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      // .then(() => Promise.all([
      //   CountriesAPI.getAllRegions(20000),
      // ]
      // ))
      // .then(([tiersList]) => {
      //   const regions = tiersList.map((item) => ({
      //     label: item.region,
      //     value: item.region,
      //   }));
      //   this.setState({
      //     regions: regions,
      //     status: PageStatus.Loaded
      //   }, () => {
          // this.fetchTiers({})
          // this.fetchStates("")
          // this.fetchDistrict({})
          .then(()=>{
          this.Total_no_panelist()
          this.Accordion_databview()
          this.setState({ status: PageStatus.Loaded })
          // this.Accordion_Question_view({})
        // });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  formValues() {
    return {
      name: this.state.name,
      description: this.state.description,
      // "gender": this.state.gender,
      "isActive": this.state.isActive,
      "profileCount": this.state.profileCount,
      "fromAge": this.state.fromAge,
      "toAge": this.state.toAge,
      "fromRegistrationDate": this.state.fromRegistrationDate,
      "toRegistrationDate": this.state.toRegistrationDate,
      "stateIds": this.state.stateIds,
      "cityIds": this.state.cityIds,
      "tierIds": this.state.tierIds,
      "segments": this.state.segmentsIds,
      "regions": this.state.regionsIds,
      "genders": this.state.dynamicFields,
      "profileTarget": this.state.profileTarget,
      "questionTarget": this.state.questionTarget,
      "answersTarget": this.state.answersTarget
    };
  }

  initializeValues(data) {
    return this.setState({
      name: data.name,
      description: data.description,
      // "gender": data.gender,
      "isActive": data.isActive,
      "profileCount": data.profileCount,
      "fromAge": data.fromAge,
      "toAge": data.toAge,
      fromRegistrationDate: data.fromRegistrationDate ? moment(data.fromRegistrationDate).format("YYYY-MM-DD") : null,
      toRegistrationDate: data.toRegistrationDate ? moment(data.toRegistrationDate).format("YYYY-MM-DD") : null,
      "stateIds": data.stateIds,
      "cityIds": data.cityIds,
      "tierIds": data.tierIds,
      selectedStatesOption: data.stateIds,
      selectedCitiesOption: data.cityIds,
      selectedTiersOption: data.tierIds,
      "segmentsIds": data.segments,
      "regionsIds": data.regions,
      selectedSegmentsOption: data.segments,
      selectedRegionsOption: data.regions,
      "dynamicFields": data.genders,
      "profileTarget": data.profileTarget,
      "questionTarget": data.questionTarget,
      "answersTarget": data.answersTarget

    }, ()=>{
      this.handleUpdateTableData();
    });
  }

  handleUpdateTableData(){
    let newArr1 = this.state.profileTarget;
    let newArr2 = this.state.questionTarget;
    let newArr3 = this.state.answersTarget;
    let newFinalArr:any = [];
    if(newArr1){
      newArr1.forEach((elem)=>{
        return Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => ProfileManagementAPI.getOne(elem))
        .then((data) => {
          newFinalArr.push({"profileName": data.name, "id": data.id, type:"Profile"});
          return this.setState({ status: PageStatus.Loaded });
        })
        .catch((error) => {
          this.setState({ status: PageStatus.Error, error: error.message });
        });
      })
    }
    if(newArr2){
      newArr2.forEach((elem)=>{
        return Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => ProfileManagementAPI.getOneQuestions(elem))
        .then( async (data) => {
          const profileDetailsvar = await ProfileManagementAPI.getOne(data.dataValues.profileId);
          newFinalArr.push({"profileName": profileDetailsvar.name, "questionName": data.dataValues.text, "id": data.dataValues.id, "questionId": data.dataValues.id, "profileId":data.dataValues.profileId, "type": "Question"});
          return this.setState({ status: PageStatus.Loaded });
        })
        .catch((error) => {
          this.setState({ status: PageStatus.Error, error: error.message });
        });
      })
    }
    if(newArr3){
      newArr3.forEach((elem)=>{
        return Promise.resolve()
        .then(() => this.setState({ status: PageStatus.Loading }))
        .then(() => ProfileManagementAPI.getOptionDetails(elem))
        .then( async (data) => {
          const profileDetailsvar = await ProfileManagementAPI.getOne(data[0].profileId);
          const questionDetailsvar = await ProfileManagementAPI.getOneQuestions(data[0].questionId);
          newFinalArr.push({"profileName": profileDetailsvar.name, "questionName": questionDetailsvar.dataValues.text, "answerName": data[0].value, "id": data[0].id, "questionId":data[0].questionId, "profileId":data[0].profileId, "type": "Answer"});
          return this.setState({ status: PageStatus.Loaded });
        })
        .catch((error) => {
          this.setState({ status: PageStatus.Error, error: error.message });
        });
      })
    }
    this.setState({selectedTableItemsArray: newFinalArr, enableDisableTargetPanelistButton: {"status":true, "text":"Updated"}});
  }

  onSubmit() {
    if (!this.props.id) {
      return this.create();
    }
    return this.update();
  }

  create() {
    let {enableDisableTargetPanelistButton, selectedTableItemsArray} = this.state;
    if((enableDisableTargetPanelistButton.status == false) && (selectedTableItemsArray.length > 0)){
      alert("You have selected the additional profile targeting but not updated the targeted panelist count.");
      return;
    }
    const valuesIn = this.formValues();
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => SamplesAPI.create(valuesIn))
      .then((data) => {
        this.props.onSubmit(data.id);
        return this.setState({ status: PageStatus.Submitted });
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }

  update() {
    let {enableDisableTargetPanelistButton, selectedTableItemsArray} = this.state;
    if((enableDisableTargetPanelistButton.status == false) && (selectedTableItemsArray.length > 0)){
      alert("You have selected the additional profile targeting but not updated the targeted panelist count.");
      return;
    }
    const valuesIn = this.formValues()
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Submitting }))
      .then(() => SamplesAPI.update(this.props.id, valuesIn))
      .then(() => {
        this.setState({ status: PageStatus.Submitted });
        return this.props.onSubmit(this.props.id);
      })
      .catch((error) => {
        this.setState({ status: PageStatus.Error, error: error.message });
      });
  }


  fetchTiers(regionsIn): Promise<void> {
    const regions = { regions: regionsIn }
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => CountriesAPI.getAllTiersBasedOnRegion(regions))
      .then((tiersList) => {
        const tierOpt = tiersList.map((item) => ({
          label: item.tier,
          value: item.tier,
        }));
        const tierOptions = removeDuplicates(tierOpt, 'label');

        this.setState({
          tiers: tierOptions,
          status: PageStatus.Loaded
        });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  fetchStates(tiers): Promise<void> {
    const regions = { tiers: tiers }
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => CountriesAPI.getStatesByTiers(regions))
      .then((tiersList) => {
        const tierOpt = tiersList.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        const tierOptions = removeDuplicates(tierOpt, 'label');

        this.setState({
          states: tierOptions,
          status: PageStatus.Loaded
        });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }

  fetchDistrict(statesId): Promise<void> {
    const regions = { stateIds: statesId }
    return Promise.resolve()
      .then(() => this.setState({ status: PageStatus.Loading }))
      .then(() => CountriesAPI.getUniqueDistrictByStateIds(regions))
      .then((tiersList) => {
        const tierOpt = tiersList.map((item) => ({
          label: item.segment,
          value: item.segment,
        }));
        const tierOptions = removeDuplicates(tierOpt, 'label');

        this.setState({
          segments: tierOptions,
          status: PageStatus.Loaded
        });
      })
      .catch((error) => {
        this.setState({ error: error.message, status: PageStatus.Error });
      });
  }


  // handleCityOnChange = async (e) => {
  //   const inputValue = e.target.value;
  //   if (inputValue.length >= 3) {
  //     this.fetchCities(inputValue)
  //   }
  // }

  // fetchCities(city): Promise<void> {
  //   const regions = { city: city }
  //   return Promise.resolve()
  //     .then(() => this.setState({ isLoading: true }))
  //     .then(() => CountriesAPI.getUniqueCitiesByDistrict(regions))
  //     .then((tiersList) => {
  //       const tierOpt = tiersList.map((item) => ({
  //         label: item.name,
  //         value: item.name,
  //       }));
  //       const tierOptions = removeDuplicates(tierOpt, 'label');

  //       this.setState({
  //         cities: tierOptions,
  //         isLoading: false
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({ error: error.message, status: PageStatus.Error });
  //     });
  // }


  reset() {
    return this.setState({
      name: "",
      description: "",
      "gender": '',
      "isActive": true,
      "profileCount": 0,
      "fromAge": null,
      "toAge": null,
      "fromRegistrationDate": null,
      "toRegistrationDate": null,
      "stateIds": [],
      "cityIds": [],
      "tierIds": [],
      segmentsIds: [],
      regionsIds: [],
      selectedRegionValues: [],
      selectedTiers: [],
      selectedStates: [],
      selectedSegments: [],
      selectedCities: [],
      selectedGenders: [],
      selectedStatesOption: [],
      selectedCitiesOption: [],
      selectedTiersOption: [],
      selectedSegmentsOption: [],
      selectedRegionsOption: [],      
      genderOptions: [{ label: "Male", value: 'Male' }, { label: "Female", value: 'Female' }, { label: "Other", value: 'Other' }],
      dynamicFields: [{
        gender: '',
        fromAge: null,
        toAge: null
      }],
      selectedTableItemsArray: [],
      profileQuestion: [],
      profileTarget: [],
      questionTarget: [],
      answersTarget: [],
      disabledCheckboxes: {},
      enableDisableTargetPanelistButton: {"status":false, "text":"Update Targeted Panelists"},
    }, ()=>{this.updatePayload();});
  }

  handleRegistrationDateChange = (e, tp)=>{
    this.setState((prev)=>{
      if(tp == "from"){
        return {fromRegistrationDate: e.target.value=="" ? null : e.target.value, minToRegistrationDate: e.target.value}
      }else{
        return {toRegistrationDate: e.target.value=="" ? null : e.target.value, maxFromRegistrationDate: e.target.value}
      }
    })
  }

  targetPanelistOnRgistrationDate = ()=>{
    if((this.state.toRegistrationDate && this.state.fromRegistrationDate) || 
      (this.state.toRegistrationDate == "" || this.state.fromRegistrationDate == "") ||
      (this.state.toRegistrationDate == null || this.state.fromRegistrationDate == null)
    ){this.updatePayload();}
  }

  handleRegionChange = async (selectedRegionsOption) => {
    this.setState({ regionsIds: selectedRegionsOption, selectedRegionsOption, selectedTiersOption: [],  selectedStatesOption: [],  selectedSegmentsOption: [],  selectedCitiesOption: [], selectedTiers: [], selectedStates: [], selectedSegments: [], selectedCities: [], });
    // const regions = selectedRegionsOption.map(option => option.value);
    // this.fetchTiers(regions)
    // add chng-------------------
    const selectedRegionValues = selectedRegionsOption.map(option => option.value);
    this.setState(prevState => {
      const prevValues = new Set(prevState.selectedRegionValues);
      const newValues = new Set(selectedRegionValues);
      selectedRegionValues.forEach(value => prevValues.add(value));
      prevState.selectedRegionValues.forEach(value => {
        if (!selectedRegionValues.includes(value)) {
          prevValues.delete(value);
        }
      });
      const updatedSelectedRegionValues = Array.from(prevValues);

      return {
        regionsIds: selectedRegionsOption,
        selectedRegionsOption,
        selectedRegionValues: updatedSelectedRegionValues
      };
    }, () => {
      this.updatePayload();
    });
    // ---------------------------
  };

  handleTierChange = async (selectedTiersOption) => {
    this.setState({ tierIds: selectedTiersOption, selectedTiersOption, selectedStatesOption: [],  selectedSegmentsOption: [],  selectedCitiesOption: [], selectedStates: [], selectedSegments: [], selectedCities: [], });
    // const regions = selectedTiersOption.map(option => option.value);
    // this.fetchStates(regions)

    //  add chng-------------------------
    const selectedTierValues = selectedTiersOption.map(option => option.value);
    this.setState(prevState => {
      const prevValues = new Set(prevState.selectedTiers);
      const newValues = new Set(selectedTierValues);
      selectedTierValues.forEach(value => prevValues.add(value));
      prevState.selectedTiers.forEach(value => {
        if (!selectedTierValues.includes(value)) {
          prevValues.delete(value);
        }
      });
      const updatedSelectedTiers = Array.from(prevValues);

      return {
        tierIds: selectedTiersOption,
        selectedTiersOption,
        selectedTiers: updatedSelectedTiers
      };
    }, () => {
      this.updatePayload();
    });
    // ----------------------------------
  };

  handleStateChange = async (selectedStatesOption) => {
    this.setState({ stateIds: selectedStatesOption, selectedStatesOption, selectedSegmentsOption: [],  selectedCitiesOption: [], selectedSegments: [], selectedCities: [], });
    // const regions = selectedStatesOption.map(option => option.value);
    // this.fetchDistrict(regions)

    // ----add chnge---------------------------------
    const selectedStateValues = selectedStatesOption.map(option => option.value);
    this.setState(prevState => {
      const prevValues = new Set(prevState.selectedStates);
      const newValues = new Set(selectedStateValues);
      selectedStateValues.forEach(value => prevValues.add(value));
      prevState.selectedStates.forEach(value => {
        if (!selectedStateValues.includes(value)) {
          prevValues.delete(value);
        }
      });
      const updatedSelectedStates = Array.from(prevValues);
      return {
        stateIds: selectedStatesOption,
        selectedStatesOption,
        selectedStates: updatedSelectedStates
      };
    }, () => {
      this.updatePayload();
    });
    // -----------------------------------------------
  };

  handleSegmentChange = async (selectedSegmentsOption) => {
    this.setState({ segmentsIds: selectedSegmentsOption, selectedSegmentsOption, selectedCitiesOption: [], selectedCities: [], });
    // const regions = selectedSegmentsOption.map(option => option.value);
    // this.fetchCities(regions)

    // -----add chnge-------------------------
    const selectedSegmentValues = selectedSegmentsOption.map(option => option.value);
    this.setState(prevState => {
      const prevValues = new Set(prevState.selectedSegments);
      const newValues = new Set(selectedSegmentValues);
      selectedSegmentValues.forEach(value => prevValues.add(value));
      prevState.selectedSegments.forEach(value => {
        if (!selectedSegmentValues.includes(value)) {
          prevValues.delete(value);
        }
      });
      const updatedSelectedSegments = Array.from(prevValues);
      return {
        segmentsIds: selectedSegmentsOption,
        selectedSegmentsOption,
        selectedSegments: updatedSelectedSegments
      };
    }, () => {
      this.updatePayload();
    });
    // ---------------------------------------
  };

  handleCityChange = async (selectedCitiesOption) => {
    this.setState({ cityIds: selectedCitiesOption, selectedCitiesOption });
    // ---------add change-------------------------
    const selectedCityValues = selectedCitiesOption.map(option => option.value);
    this.setState(prevState => {
      const prevValues = new Set(prevState.selectedCities);
      const newValues = new Set(selectedCityValues);
      selectedCityValues.forEach(value => prevValues.add(value));
      prevState.selectedCities.forEach(value => {
        if (!selectedCityValues.includes(value)) {
          prevValues.delete(value);
        }
      });
      const updatedSelectedCities = Array.from(prevValues);

      return {
        cityIds: selectedCitiesOption,
        selectedCitiesOption,
        selectedCities: updatedSelectedCities
      };
    }, () => {
      this.updatePayload();
    });
    // --------------------------------------------
  };



  // handleDynamicFieldChange = (e, index, field) => {
  //   const { dynamicFields } = this.state;
  //   const updatedFields = [...dynamicFields];
  //   if (field === 'gender') {
  //     updatedFields[index][field] = e;
  //     this.setState({ dynamicFields: updatedFields });


  //   } else {
  //     updatedFields[index][field] = e;
  //     this.setState({ dynamicFields: updatedFields });
  //   }
  // };

  handleAgeFilterValue = (e, index, field) => {
    const { dynamicFields } = this.state;
    const updatedFields = [...dynamicFields];
    updatedFields[index][field] = e;
    this.setState({ dynamicFields: updatedFields });
  }

  handleDynamicFieldChange = (e, index, field) => {
    const { dynamicFields } = this.state;
    const updatedFields = [...dynamicFields];
    updatedFields[index][field] = e;

    this.setState({ dynamicFields: updatedFields }, () => {
      let selectedGender: string[] = [];
      if (field === 'gender') {
        if (Array.isArray(e)) {
          selectedGender = e.map(option => option.value);
        } else {
          selectedGender = e.value ? [e.value] : [];
        }
      }

      this.setState(prevState => {
        let newSelectedGenders;
        if (field === 'gender') {
          const currentGenders = prevState.selectedGenders;
          const updatedGenderField = updatedFields[index][field];

          if (Array.isArray(updatedGenderField)) {
            newSelectedGenders = currentGenders.filter(gender =>
              selectedGender.includes(gender) || updatedGenderField.some(option => option.value === gender)
            ).concat(
              updatedGenderField.map(option => option.value).filter(gender => !currentGenders.includes(gender))
            );
          } else {
            if (updatedGenderField.value) {
              newSelectedGenders = [...currentGenders, updatedGenderField.value];
            } else {
              const removedGender = prevState.dynamicFields[index].gender.value;
              newSelectedGenders = currentGenders.filter(gender => gender !== removedGender);
            }
          }
        } else {
          newSelectedGenders = prevState.selectedGenders;
        }
        return { selectedGenders: newSelectedGenders };
      }, ()=>{
        if(field === 'gender'){
          this.updatePayload();
        }else{
          if(updatedFields[index].toAge && updatedFields[index].fromAge){
            this.updatePayload();
          }
        }
        });
    });
  };

  // ==============Add functionallity===================
  updatePayload = () => {
    // const {
    //   selectedGenders = [],
    //   dynamicFields = [],
    //   selectedRegionValues = [],
    //   selectedTiers = [],
    //   selectedStates = [],
    //   selectedSegments = [],
    //   selectedCities = [],
    //   profileTarget = [],  // Consistent variable name
    //   questionTarget,
    //   answersTarget
    // } = this.state;
    // const fromAge = dynamicFields[0]?.fromAge;
    // const toAge = dynamicFields[0]?.toAge;
    // const requestBody = {
    //   ...(selectedGenders.length > 0 && { gender: selectedGenders }),
    //   ...(dynamicFields.length > 0 && {
    //     ...(fromAge && fromAge !== "" && { fromAge }),
    //     ...(toAge && toAge !== "" && { toAge }),
    //     // fromAge: dynamicFields[0]?.fromAge,
    //     // toAge: dynamicFields[0]?.toAge
    //   }),
    //   ...(selectedRegionValues.length > 0 && { regions: selectedRegionValues }),
    //   ...(selectedTiers.length > 0 && { tier: selectedTiers }),
    //   ...(selectedStates.length > 0 && { state: selectedStates }),
    //   ...(selectedSegments.length > 0 && { segment: selectedSegments }),
    //   ...(selectedCities.length > 0 && { city: selectedCities }),
    //   ...(profileTarget.length > 0 && { profileTarget: profileTarget }),// Add profileTarget conditionally
    //   ...(questionTarget.length > 0 && { questionTarget: questionTarget }), // Include checked IDs
    //   ...(answersTarget.length > 0 && { answersTarget: answersTarget }) // Fixed key name
    // };

    // this.targetpanelist(requestBody);
    this.targetpanelist();
  };
  targetpanelist = async () => {
    const {
      // selectedGenders = [],
      dynamicFields = [],
      selectedRegionValues = [],
      selectedTiers = [],
      selectedStates = [],
      selectedSegments = [],
      selectedCities = [],
      profileTarget = [],  // Consistent variable name
      questionTarget,  // This should now be updated with checked IDs
      answersTarget,  // This should now be updated with checked IDs
      toRegistrationDate,
      fromRegistrationDate,
    } = this.state;
    // const fromAge = dynamicFields[0]?.fromAge;
    // const toAge = dynamicFields[0]?.toAge;
    // Construct requestBody
    const requestBody = {
      // ...(selectedGenders.length > 0 && { gender: selectedGenders }),
      ...(dynamicFields.length > 0 && {
        // ...(fromAge && fromAge !== "" && { fromAge }),
        // ...(toAge && toAge !== "" && { toAge }),
        ...({genders: dynamicFields.filter((ele)=> ele.gender != "" || (ele.fromAge != null && ele.toAge != null))})
      }),
      ...(selectedRegionValues.length > 0 && { regions: selectedRegionValues }),
      ...(selectedTiers.length > 0 && { tier: selectedTiers }),
      ...(selectedStates.length > 0 && { state: selectedStates }),
      ...(selectedSegments.length > 0 && { segment: selectedSegments }),
      ...(selectedCities.length > 0 && { city: selectedCities }),
      ...(profileTarget.length > 0 && { profileTarget: profileTarget }), // Add profileTarget conditionally
      ...(questionTarget.length > 0 && { questionTarget: questionTarget }), // Include checked IDs
      ...(answersTarget.length > 0 && { answersTarget: answersTarget }), // Fixed key name
      // ...((toRegistrationDate && fromRegistrationDate) && {
        ...({ toRegistrationDate, fromRegistrationDate }),
      // }),
    };

    // try {
    //   const response = await SamplesAPI.targetpanelistAPI(requestBody);

    //   if (response && response.data && response.data.data) {
    //     const { targetedCount, panelistCount } = response.data.data;

    //     const targetedElement = document.getElementById('targeted_panellist');
    //     const remainingElement = document.getElementById('remaning_panellist');

    //     if (targetedElement) {
    //       targetedElement.innerHTML = targetedCount;
    //       this.setState({profileCount: targetedCount});
    //     } else {
    //       console.error("Element with ID 'targeted_panellist' not found.");
    //     }

    //     if (remainingElement) {
    //       const difference = panelistCount - targetedCount;
    //       remainingElement.innerHTML = difference.toString();
    //     } else {
    //       console.error("Element with ID 'remaning_panellist' not found.");
    //     }
    //   } else {
    //     console.error("Invalid response format:", response);
    //   }
    // } catch (error) {
    //   console.error("Error fetching total number of panelists:", error);
    // }

    return Promise.resolve()
    .then(() => this.setState({ status: PageStatus.Loading }))
    .then(() => SamplesAPI.targetpanelistAPI(requestBody))
    .then((response) => {
        this.setState({ status: PageStatus.Loaded },()=>{
          const { targetedCount, panelistCount } = response.data.data;
          const targetedElement = document.getElementById('targeted_panellist');
          const remainingElement = document.getElementById('remaning_panellist');
          if (targetedElement) {
            targetedElement.innerHTML = targetedCount;
            this.setState({profileCount: targetedCount, });
          } else {
            console.error("Element with ID 'targeted_panellist' not found.");
          }
          if (remainingElement) {
            const difference = panelistCount - targetedCount;
            remainingElement.innerHTML = difference.toString();
          } else {
            console.error("Element with ID 'remaning_panellist' not found.");
          }
        })
    })
    .catch((error) => {
      this.setState({ error: error.message, status: PageStatus.Error });
    });
  };

  // updatePayload = () => {
  //   this.targetpanelist();
  // };
  // ===================================================

  handleAddMore = () => {
    // const { gender, fromAge, toAge } = this.state.dynamicFields;
    const newField = { gender:"", fromAge: null, toAge: null };
    this.setState((prevState) => ({
      dynamicFields: [...prevState.dynamicFields, newField],
    }));
  };

  handleDelete = (index) => {
    if (this.state.dynamicFields.length > 1) {
      this.setState((prevState) => ({
        dynamicFields: prevState.dynamicFields.filter((_, i) => i !== index),
      }),()=>this.targetpanelist());
    }
  };
  // add functionality no of pannel list view...  change ---------------------
  Total_no_panelist = async () => {
    try {
      const response = await SamplesAPI.Total_no_panelist();
      if (response) {
        const { panelistCount } = response.data.data;
        document.getElementById('no_of_panelist').innerHTML = panelistCount;
        document.getElementById('targeted_panellist').innerHTML = this.state.data ? this.state.data.profileCount : panelistCount;
        document.getElementById('remaning_panellist').innerHTML = this.state.data ? (panelistCount - this.state.data.profileCount) : 0;
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching total number of panelists:", error);
    }
  }
  // ---------------------------- Targeting profile function ---------------------------------
  Accordion_databview = async () => {
    try {
      const response = await ProfileManagementAPI.getAll();
      if (response && Array.isArray(response)) {
        console.log(response, "profile response");
        this.setState({ accordience: response });
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  }

  // Fetch questions based on the selected profile ID
  Accordion_Question_view = async (id, profileText) => {

    try {
      const response = await ProfileManagementAPI.getAllQuestions(10000, id);
      if (response && Array.isArray(response)) {
        let newResponse = response.map((ele)=>{ele.profileName = profileText; return ele;});
        this.setState({ profileQuestion: newResponse });
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching questions data:", error);
    }
  }
  Accordion_Answers_view = async (id, queText, proText) => {
    try {
      const response = await ProfileManagementAPI.getOneQuestions(id);

      if (response && response.options) {
        let newAnswerObj = response.options.map((elem)=>{ elem.profileId = response.dataValues.profileId; elem.questionName = queText; elem.profileName = proText; return elem; });
        this.setState({ profileAnswers: newAnswerObj });
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching answers data:", error);
    }
  }

  handleProfileClick = (profileId, profileName) => {

    this.setState({ selectedProfileId: profileId, profileQuestion: [] });
    this.Accordion_Question_view(profileId, profileName);
  }
  handleQuestionClick = (questionId, questionText, profileText) => {


    this.setState({ selectedQuestionId: questionId, profileAnswers: [] });
    this.Accordion_Answers_view(questionId, questionText, profileText);
  }

  //  =================CHECKBOX TARGETED PRPOFILE============
  handleProfileCheckbox = (profileId, isChecked, wholeElem, type) => {
    this.setState(prevState => {
      const checkedProfileTarget = new Set(prevState.profileTarget);
      const disabledCheckboxes = { ...prevState.disabledCheckboxes };

      if (isChecked) {
        checkedProfileTarget.add(profileId);
        disabledCheckboxes[profileId] = true; // Disable related checkboxes for this profile
        let newObj = {"profileName": wholeElem.name, "id": wholeElem.id, type};
        this.addDataInTableFunc(newObj);
      } else {
        checkedProfileTarget.delete(profileId);
        delete disabledCheckboxes[profileId]; // Enable checkboxes for this profile
        this.removeItemFromTableFunc(wholeElem.id, type);
      }
      return {
        // profileTarget: Array.from(checkedProfileTarget),
        disabledCheckboxes
      };
    }, 
    // () => {
    //   this.updatePayload();
    // }
    );
  };
  //  =================CHECKBOX TARGETED QUESTION============
  handleQuestionCheckbox = (questionId, isChecked, wholeElem, type) => {
    this.setState(
      (prevState) => {
        // Use a Set to manage the state for checked questions
        const updatedCheckedQuestions = new Set(prevState.questionTarget);

        if (isChecked) {
          // Add questionId to the checked questions set
          updatedCheckedQuestions.add(questionId);
          let newObj = {"profileName": wholeElem.profileName, "questionName": wholeElem.text, "id": wholeElem.id, "questionId":wholeElem.id, "profileId":wholeElem.profileId, type};
          this.addDataInTableFunc(newObj);
        } else {
          // Remove questionId from the checked questions set
          updatedCheckedQuestions.delete(questionId);
          this.removeItemFromTableFunc(wholeElem.id, type);
        }

        // return { questionTarget: Array.from(updatedCheckedQuestions) };
      },
      // () => {
      //   this.updatePayload();
      // }
    );
  };

  addDataInTableFunc = (paramElem) => {
    this.setState((prev) => {
      let filteredElems:String[] = [];
      if(prev.selectedTableItemsArray){
        filteredElems = prev.selectedTableItemsArray.filter(elem => {
          if (paramElem.type === "Profile") { 
            return elem.profileId !== paramElem.id; 
          } 
          else if (paramElem.type === "Question") { 
            return elem.questionId !== paramElem.id; 
          }
          return true;
        });
      }

      const newState = {
        ...prev,
        selectedTableItemsArray: [...filteredElems, paramElem],
        enableDisableTargetPanelistButton: {"status":false, "text":"Update Targeted Panelists"},
      };

      newState.profileTarget = [];
      newState.questionTarget = [];
      newState.answersTarget = [];
      [...filteredElems, paramElem].forEach((elem)=>{
        if (elem.type == "Profile") { newState.profileTarget.push(elem.id) } 
        else if (elem.type == "Question") { newState.questionTarget.push(elem.id) }
        else{ newState.answersTarget.push(elem.id) }
      })
      return newState;
    },
    () => {
      // this.updatePayload();
    });
  }

  removeItemFromTableFunc = (paramElem, elemType) =>{
    this.setState((prevState)=>{
      let newFilteredArr = prevState.selectedTableItemsArray.filter( elem =>elem.id != paramElem );
      if (elemType === "Profile") {  
        let disabledCheckboxes = { ...prevState.disabledCheckboxes };
        delete disabledCheckboxes[paramElem];
        let newProfileFilter = prevState.profileTarget.filter( elem => elem != paramElem );
        return {selectedTableItemsArray: newFilteredArr, profileTarget: newProfileFilter, disabledCheckboxes,  enableDisableTargetPanelistButton: {"status":false, "text":"Update Targeted Panelists"}};
      } 
      else if (elemType === "Question") {  
        let newQuestionFilter = prevState.questionTarget.filter( elem => elem != paramElem );
        return {selectedTableItemsArray: newFilteredArr, questionTarget: newQuestionFilter,  enableDisableTargetPanelistButton: {"status":false, "text":"Update Targeted Panelists"}};
      }
      else if (elemType === "Answer") {  
        let newAnswerFilter = prevState.answersTarget.filter( elem => elem != paramElem );
        return {selectedTableItemsArray: newFilteredArr, answersTarget: newAnswerFilter,  enableDisableTargetPanelistButton: {"status":false, "text":"Update Targeted Panelists"}};
      }
    },
    () => {
      if(this.state.selectedTableItemsArray.length == 0){
        this.updatePayload();
      }
    })
  }

  filterPanelistByProfileTargeting = ()=>{
    this.setState({enableDisableTargetPanelistButton: {"status":true, "text":"Updated"}}, ()=>this.updatePayload());
  }

  // handleQuestionCheckbox = (questionId, isChecked) => {
  //   this.setState(prevState => {
  //     let updatedCheckedQuestions = [...prevState.questionTarget];

  //     if (isChecked) {
  //       if (!updatedCheckedQuestions.includes(questionId)) {
  //         updatedCheckedQuestions.push(questionId);
  //       }
  //     } else {
  //       updatedCheckedQuestions = updatedCheckedQuestions.filter(id => id !== questionId);
  //     }
  //     return { checkedQuestions: updatedCheckedQuestions };
  //   }, () => {
  //     this.updatePayload();
  //   });
  // };


  handleAnswersCheckbox = (answersIdselected, isChecked, wholeElem, type) => {
    this.setState(
      (prevState) => {
        // Use a Set to manage the state for checked answers
        const updatedCheckedAnswers = new Set(prevState.answersTarget || []);

        if (isChecked) {
          // Add answersIdselected to the checked answers set
          updatedCheckedAnswers.add(answersIdselected);
          let newObj = {"profileName": wholeElem.profileName, "questionName": wholeElem.questionName, "answerName": wholeElem.value, "id": wholeElem.id, "questionId":wholeElem.questionId, "profileId":wholeElem.profileId, type};
          this.addDataInTableFunc(newObj);
        } else {
          // Remove answersIdselected from the checked answers set
          updatedCheckedAnswers.delete(answersIdselected);
          this.removeItemFromTableFunc(wholeElem.id, type);
        }

        // Convert the Set back to an array for state update
        // return { answersTarget: Array.from(updatedCheckedAnswers) };
      },
      // () => {
      //   this.updatePayload(); // Callback to handle the updated payload
      // }
    );
  };

  handleIpFocusFunc = async (type) => {
    try {
      let payloadData = {
        "type": type,
        "region" : this.state.selectedRegionValues, 
        "tiers":  this.state.selectedTiers, 
        "states":  this.state.selectedStates, 
        "districts" :  this.state.selectedSegments, 
      };
      const response:any = await SamplesAPI.getsegmentwisedetails(payloadData);
      if (response) {
        if(type == "region"){
          const regions = response.data.map((item) => ({ label: item.region, value: item.region, }));
          this.setState({ regions: regions, })
        }

        if(type == "tier"){
          const tiers = response.data.map((item) => ({ label: item.tier, value: item.tier, }));
          this.setState({ tiers: tiers, });
        }

        if(type == "states"){
          const states = response.data.map((item) => ({ label: item.name, value: item.id, }));
          this.setState({ states: states, });
        }

        if(type == "district"){
          const segments = response.data.map((item) => ({ label: item.segment, value: item.segment, }));
          this.setState({ segments: segments, });
        }

        if(type == "cities"){
          const cities = response.data.map((item) => ({ label: item.name, value: item.id, }));
          this.setState({ cities: cities, });
        }
        
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error fetching total number of panelists:", error);
    }
  }



  // ===============================================================
  render() {
    const { disabledCheckboxes, selectedProfileId, profileQuestion, profileAnswers } = this.state;
    return (
      <Modal
        centered
        size="xl"
        backdrop="static"
        onHide={this.props.onClose}
        show={this.props.show}
        style={{ zIndex: 1201 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='d-flex justify-content-between w-100'>
              {/* Left Side */}
              <div className='d-flex align-items-center'>
                <h3 >Sample</h3>
              </div>

              {/* Right Side */}
              <div className='d-flex align-items-center mx-3'>
                <div className='mx-3'>
                  <h6 htmlFor="no_of_panelist">No. Of Panelist</h6>
                  <h6 id='no_of_panelist' className='text-success'>0</h6>
                </div>
                <div className='mx-3'>
                  <h6 htmlFor="targeted_panellist">Targeted Panelist Count</h6>
                  <h6 id='targeted_panellist' className='text-info'>0</h6>
                </div>
                <div className='mx-3'>
                  <h6 htmlFor="remaining_panellist">Remaining Panelist</h6>
                  <h6 id='remaning_panellist' className='remaning' >0</h6>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ maxHeight: '78vh', overflow: 'auto' }}>
          <Show when={this.state.status === PageStatus.Loading}>
            <div className="d-flex justify-content-center w-100 p-5">
              <Spinner animation="border" variant="primary" />
            </div>
          </Show>

          <Alert variant="danger" show={this.state.status === PageStatus.Error}>
            {this.state.error}
          </Alert>

          <Show when={this.state.status === PageStatus.Loaded}>
            <form onSubmit={this.props.handleSubmit(
              (event) => this.onSubmit(),
            )}
            >

              <div className="row mt-2">
                <div className="col-md-6">
                  <label htmlFor="name">Name*</label>
                  <input
                    className="form-control"
                    name="name"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    value={this.state.name}
                    placeholder="Enter here"
                    required
                  />
                </div>

                <div className="col-md-4 mt-5 ml-60">
                  <input
                    type="checkbox"
                    id="useUniqueLinks"
                    name="useUniqueLinks"
                    className="custom-control-input"
                    checked={this.state.isActive}
                    onChange={() => this.setState({ isActive: !this.state.isActive })}
                  />
                  <label className="custom-control-label" htmlFor="useUniqueLinks">Active*</label>
                </div>


              </div>


              {/*  add few label */}
              {/* <div className='row mt-2'>
                <div className='col-md-2 mx-2'>
                  <label htmlFor="no_of_panelist">No. Of Panelist</label>
                  <b><p id='no_of_panelist' className='text-success'> 0 </p></b>
                </div>

                <div className='col-md-2 mx-3'>
                  <label htmlFor="no_of_panelist">Targeted Panelist Count</label>
                  <b><p className="mx-3 text-info" id='targeted_panellist'> 0 </p></b>
                </div>

                <div className='col-md-2'>
                  <label htmlFor="remaning_panellist">Remaning Panellist</label>
                  <b >  <p id='remaning_panellist' className='remaning' >0</p> </b>
                </div>

              </div> */}
              {/*  */}

              {/* <div className="row mt-2">
                  <div className="col">
                    <label htmlFor="name">No Of Respondent*</label>
                    <input
                        className="form-control"
                        name="profileCount"
                        type="number"
                        min={0}
                        onChange={(e) => this.setState({profileCount: e.target.value})}
                        value={this.state.profileCount}
                        placeholder="Enter here"
                        required
                    />
                  </div>
                </div> */}


              <div className="row ">
                <div className="col">
                  <label htmlFor="description">Description</label>
                  <input
                    className="form-control"
                    type="textbox"
                    name="description"
                    onChange={(e) => this.setState({ description: e.target.value })}
                    value={this.state.description}
                    placeholder="Enter here"

                  />
                </div>
              </div>

              {this.state.dynamicFields.map((field, index) => (
                <div key={index} className="jumbotron bg-white p-3 border shadow-sm mt-4">
                  <div className="row">
                    <div className="col">
                      <label htmlFor='gender'>Gender</label>
                      <Select
                        name='state'
                        id='state'
                        onChange={(e) => this.handleDynamicFieldChange(e, index, 'gender')}
                        value={field.gender}
                        isMulti
                        required
                        options={this.state.genderOptions}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="fromAge">Min Age</label>
                      <input
                        className="form-control"
                        id="fromAge"
                        type="number"
                        name="fromAge"
                        value={field.fromAge}
                        onBlur={(e) => this.handleDynamicFieldChange(parseInt(e.target.value, 10), index, 'fromAge')}
                        onChange={(e) => this.handleAgeFilterValue(parseInt(e.target.value, 10), index, 'fromAge')}
                        placeholder="Enter start Age"
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="fromAge">Max Age</label>
                      <input
                        className="form-control"
                        id="toAge"
                        type="number"
                        name="toAge"
                        value={field.toAge}
                        onBlur={(e) => this.handleDynamicFieldChange(parseInt(e.target.value, 10), index, 'toAge')}
                        onChange={(e) => this.handleAgeFilterValue(parseInt(e.target.value, 10), index, 'toAge')}
                        placeholder="Enter end Age"
                      />
                    </div>
                  </div>
                  <Show when={this.state.dynamicFields.length > 1}>
                    <div className="col mt-4">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.handleDelete(index)}
                      >
                        Delete
                      </button>
                    </div>
                  </Show>
                </div>
              ))}

              <button
                type="button"
                className="btn-sm btn-primary mt-3"
                onClick={() => this.handleAddMore()}
              >
                Add More
              </button>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="fromRegistrationDate">Registration Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="fromRegistrationDate"
                    name="fromRegistrationDate"
                    max={this.state.maxFromRegistrationDate}
                    onChange={(e)=>{this.handleRegistrationDateChange(e, "from")}}
                    onBlur={()=>{this.targetPanelistOnRgistrationDate()}}
                    value={this.state.fromRegistrationDate}
                    placeholder="Enter here"

                  />
                </div>
                <div className="col">
                  <label htmlFor="toRegistrationDate">Registration End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="toRegistrationDate"
                    name="toRegistrationDate"
                    min={this.state.minToRegistrationDate}
                    onChange={(e)=>{this.handleRegistrationDateChange(e, "to")}}
                    onBlur={()=>{this.targetPanelistOnRgistrationDate()}}
                    value={this.state.toRegistrationDate}
                    placeholder="Enter here"

                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="text">Regions</label>
                  <Select
                    name='state'
                    id='state'
                    onFocus={()=>{this.handleIpFocusFunc("region")}}
                    onChange={this.handleRegionChange}
                    value={this.state.selectedRegionsOption}
                    isMulti

                    options={this.state.regions}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="text">Tiers</label>
                  <Select
                    name='tiers'
                    id='tiers'
                    onFocus={()=>{this.handleIpFocusFunc("tier")}}
                    onChange={this.handleTierChange}
                    value={this.state.selectedTiersOption}
                    isMulti
                    options={this.state.tiers}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="text">States</label>
                  <Select
                    name='state'
                    id='state'
                    onFocus={()=>{this.handleIpFocusFunc("states")}}
                    onChange={this.handleStateChange}
                    value={this.state.selectedStatesOption}
                    isMulti

                    options={this.state.states}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="text">District</label>
                  <Select
                    name='state'
                    id='state'
                    onFocus={()=>{this.handleIpFocusFunc("district")}}
                    onChange={this.handleSegmentChange}
                    value={this.state.selectedSegmentsOption}
                    isMulti
                    options={this.state.segments}
                  />
                </div>
              </div>


              <div className="row mt-2">
                <div className="col">
                  <label htmlFor="text">Cities</label>
                  <Select
                    name='cities'
                    id='cities'
                    onFocus={()=>{this.handleIpFocusFunc("cities")}}
                    onChange={this.handleCityChange}
                    value={this.state.selectedCitiesOption}
                    isMulti
                    options={this.state.cities}
                    // isSearchable
                    isLoading={this.state.cities.length === 0}
                    // onInputChange={(e) => this.handleCityOnChange(e)}
                    // onKeyDown={this.handleCityOnChange}

                  />
                </div>
              </div>

              <Show when={this.state.selectedTableItemsArray.length > 0}>
                  <div className="row mt-3">
                    <div className="col">
                      <div className="d-flex justify-content-between">
                      <h4>Selected Items:</h4>
                      <Button
                        onClick={()=> this.filterPanelistByProfileTargeting() }
                        variant="primary"
                        size="sm"
                        className="mx-1 mb-2"
                        disabled={this.state.enableDisableTargetPanelistButton.status}
                      >
                    {this.state.enableDisableTargetPanelistButton.text}
                  </Button>
                      </div>
                      <Table responsive size="sm" bordered>
                        <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Profile</th>
                            <th>Question</th>
                            <th>Answer</th>
                            <th>Action</th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                          this.state.selectedTableItemsArray.map((info, index) => (
                              <tr key={info.id}>
                                  <td>{index + 1}</td>
                                  <td>{info.profileName ?? "-"}</td>
                                  <td>{info.questionName ?? "-"}</td>
                                  <td>{info.answerName ?? "-"}</td>
                                  <td>
                                    <Confirmation onAction={() => this.removeItemFromTableFunc(info.id, info.type)} body="Do you want to delete item ?">
                                      <Button
                                        variant="danger"
                                        size="sm"
                                        className="mx-1"
                                      >
                                        Delete
                                      </Button>
                                    </Confirmation>
                                  </td>
                              </tr>
                          ))
                        }
                        </tbody>
                      </Table>
                    </div>
                  </div>
              </Show>

              {/* Add accordience */}
              <div className="row mt-2">
                <div className="col">
                  <Accordion>
                    <Card>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0" className="toggle-left float-left">
                        <div className='d-flex align-items-center w-100'>
                          <i className="fa fa-chevron-down mr-2" />
                          <span>Additional Target Profile</span>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Accordion>
                            {this.state.accordience.map((item) => (
                              <Card key={item.id} className="mb-2">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={`profile-${item.id}`}
                                  className="toggle-left d-flex align-items-center w-100"
                                  onClick={() => this.handleProfileClick(item.id, item.name)}
                                >
                                  <i className="fa fa-chevron-down mr-2" />
                                  <input
                                    className='mx-4'
                                    type="checkbox"
                                    // checked={this.state.profileTarget ? this.state.profileTarget.includes(item.id) : false}
                                    checked={this.state.profileTarget ? this.state.profileTarget.some((number) => number == item.id) : ""}
                                    onChange={(e) => this.handleProfileCheckbox(item.id, e.target.checked, item, "Profile")}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  <span>{item.name}</span>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={`profile-${item.id}`}>
                                  <Card.Body>
                                    {selectedProfileId === item.id && profileQuestion.length > 0 && (
                                      <Accordion>
                                        {profileQuestion.map((question) => (
                                          <Card key={question.id} className="mb-2">
                                            <Accordion.Toggle
                                              as={Button}
                                              variant="link"
                                              eventKey={`question-${question.id}`}
                                              className="toggle-left d-flex align-items-center w-100"

                                              onClick={() => this.handleQuestionClick(question.id, question.text, question.profileName)}
                                            >
                                              <i className="fa fa-chevron-down mr-2" />
                                              <input
                                                id="questioncheckbox"
                                                className='mx-3'
                                                type="checkbox"
                                                checked={disabledCheckboxes[item.id] || (this.state.questionTarget ? this.state.questionTarget.some((number) => number == question.id) : false) }
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => this.handleQuestionCheckbox(question.id, e.target.checked, question, 'Question')}
                                                disabled={disabledCheckboxes[item.id] || false} // Disable based on state
                                              />

                                              <span className='text-left'>{question.text}</span>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={`question-${question.id}`}>
                                              <Card.Body>
                                                {this.state.selectedQuestionId === question.id && profileAnswers.length > 0 && (
                                                  <Accordion>
                                                    {profileAnswers.map((answer) => (
                                                      <Card key={answer.id} className="mb-2">
                                                        <Accordion.Toggle
                                                          as={Card.Header}
                                                          eventKey={`answer-${answer.id}`}
                                                          className="d-flex align-items-center justify-content-left"
                                                        >
                                                          <input
                                                            id="answerscheckbox"
                                                            className='mx-3'
                                                            type="checkbox"
                                                            checked={(this.state.answersTarget ? this.state.answersTarget.some((number) => number == answer.id) : "") || disabledCheckboxes[answer.profileId] || (this.state.questionTarget ? this.state.questionTarget.some((number) => number == answer.questionId) : "")}
                                                            onChange={(e) => this.handleAnswersCheckbox(answer.id, e.target.checked, answer, "Answer")}
                                                            disabled={disabledCheckboxes[answer.profileId] || (this.state.questionTarget ? this.state.questionTarget.some((number) => number == answer.questionId) : "") || false} // Disable based on state
                                                          />

                                                          <span className="d-flex align-items-left justify-content-left">{answer.value}</span>
                                                        </Accordion.Toggle>
                                                      </Card>
                                                    ))}
                                                  </Accordion>
                                                )}
                                              </Card.Body>
                                            </Accordion.Collapse>
                                          </Card>
                                        ))}
                                      </Accordion>
                                    )}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                          </Accordion>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
              {/* =============== */}


              <Alert variant="danger" show={!!this.state.error} className="mt-2">
                {this.state.error}
              </Alert>

              <div className="d-flex align-items-center mt-2">
                <button
                  type="submit"
                  disabled={!this.state.name}
                  className="btn btn-primary mr-3"
                >
                  Submit
                </button>

                <button
                  type="button"
                  disabled={false}
                  onClick={() => this.reset()}
                  className="btn btn-light mr-3"
                >
                  Reset
                </button>

                <Show when={this.state.status === PageStatus.Submitting}>
                  <Spinner animation="border" variant="primary" />
                </Show>
              </div>
            </form>
          </Show>
        </Modal.Body>
      </Modal >
    );
  }
}

const dataFormRedux = reduxForm < FormValue, any> ({
  form: 'dataForm',
})(Form);


const dataFormWithRouter = withRouter(dataFormRedux);

export { dataFormWithRouter as Form };
