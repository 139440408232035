import React from 'react';
import { reduxForm } from 'redux-form';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { connect } from "react-redux"; // Use connect for class components
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import Language from "../../Languages/Login/content.json";
import { NewsLetterAPI } from "../../API"; // Ensure API paths are correct
import { PageStatus } from 'enums'; // Import PageStatus correctly
import moment from 'moment';
class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageContent: this.props.language === 'hi' ? Language.profilesHindi : Language.profilesEnglish,
      status: PageStatus.Loading, // Initialize the status as Loading
      notifications: [], // Store notifications
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.setState({
        pageContent: this.props.language === 'hi' ? Language.profilesHindi : Language.profilesEnglish,
      });
    }
  }

  componentDidMount() {
    this.appInboxDetails(); // Fetch notifications when component mounts
  }

  // Fetch notification details
  appInboxDetails = async () => {
    try {
      const response = await NewsLetterAPI.appInbox(this.props.userId);
      if (response && response.data && Array.isArray(response.data.notification)) {
        this.setState({
          notifications: response.data.notification,
          status: PageStatus.Loaded,
        });
        const captureNotificationCount = await NewsLetterAPI.updateNotification({"userId": this.props.userId});
      } else {
        this.setState({ status: PageStatus.Error });
      }
    } catch (error) {
      this.setState({ status: PageStatus.Error });
    }
  };


  renderNotifications() {
    const { notifications } = this.state;
    if (notifications.length === 0) {
      return <Alert variant="danger" className='text-center'>{this.props.language === 'hi' ? 'कोई सूचनाएँ नहीं हैं।' : 'No notifications available.'}</Alert>;
    }
    return notifications.map((notification, index) => (
      <Alert variant="info" key={index}>
        <div >
          {/* <div className="row">
            <div className="col-md-8">
              <h6 className="font-weight-bold">Name:</h6>
              <p>{notification.name}</p>
            </div>
            <div className="col-md-4 text-md-right">
              <h6 className="font-weight-bold">Send Date:</h6>
              <p>{notification.sendDate}</p>
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-8">
              <h6 className="font-weight-bold">Subject</h6>
              <p>{notification.subject}</p>
            </div>
            <div className="col-md-4 text-md-right">
              <h6 className="font-weight-bold">Date</h6>
              <p>{moment(notification.sendDate).format('D - MMM - YYYY')}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h6 className="font-weight-bold">Message</h6>
              <div
                dangerouslySetInnerHTML={{
                  __html: notification.body.replace(/<p>/g, '<div className="p-0 m-0">').replace(/<\/p>/g, '</div>').replace(/<p>\s*<\/p>/g, ''),
                }}
              ></div>
            </div>
          </div>
        </div>
      </Alert>

    ));
  }

  render() {
    const { pageContent, status } = this.state;

    return (
      <>
        <GridContainer>
          <Card>
            <CardHeader color="primary">
              <div className="d-flex align-items-center justify-content-between">
                <h4>{this.props.language === 'hi' ? 'इनबॉक्स' : 'Inbox'}</h4>
                <div>
                  <Button
                    onClick={() => this.props.history.push('/panelist/profile-overview')}
                    variant="danger"
                    size="sm"
                  >
                    {this.props.language === 'hi' ? 'बंद करे' : 'Close'}
                  </Button>
                </div>
              </div>
            </CardHeader>
          </Card>
        </GridContainer>

        {/* Loading spinner */}
        {status === PageStatus.Loading && (
          <div className="d-flex justify-content-center w-100 p-5">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {/* Error state */}
        {status === PageStatus.Error && (
          <Alert variant="danger">
            {this.props.language === 'hi' ? 'कुछ गलत हो गया। कृपया पुनः प्रयास करें।' : 'Something went wrong. Please try again.'}
          </Alert>
        )}

        {/* View notifications */}
        {status === PageStatus.Loaded && this.renderNotifications()}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.adminUser.adminUser.language,
    userId: state.adminUser.adminUser.userId,
  };
};

const ConnectedNotification = withRouter(connect(mapStateToProps)(Notification));
export { ConnectedNotification as Notification };
